import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-abb.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/abb-digital-factory-tours/digital-factory-tours_Headervideo_V3.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const AbbDigitalFactoryTours = function (props, ref) {

    const data = useStaticQuery(graphql`
        query AbbDigitalFactoryToursQuery {
            heropic: file(relativePath: { eq: "images/projekte/abb-digital-factory-tours/thumbleiste/dft-img-Detailseite_02.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/abb-digital-factory-tours/thumbleiste/dft-img-Detailseite_02.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }
            
            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/abb-digital-factory-tours/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Webanwendung", "https://factory-tour-stotz-kontakt.abb.com/#/tour/heidelberg"] },
        { title: "Leistungen", objects: ["konzept", "design", "produktion", "redaktion", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="ABB Digital Factory Tours"
                description="Q:marketing realisiert virtuelle Fabrikführungen durch die Produktionsstandorte von ABB STOTZ-Kontakt, Busch-Jaeger und Striebel &amp; John"
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>ABB Digital Factory Tours</h1>                                                
                        <p className="introtext">Mit den ABB Factory Tours können die Produktionstandorte in Lüdenscheid (Busch-Jaeger), Heidelberg (ABB STOTZ-KONTAKT) und Sasbach (Striebel & John) virtuell via 360°-Rundgang erkundet werden.</p>
                        <AnchorLink to="/projekte/virtuelle-produktpraesentationen/digital-factory-tours/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />                        
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Exklusiver Einblick in den Entstehungsprozess der Produkte.",
                        "Virtueller Rundgang in 360° ermöglicht Blick in alle Richtungen.",
                        "Interviews mit Mitarbeitern geben fachkundige Erläuterungen zu den einzelnen Stationen.",
                        "Eine virtuelle Assistentin führt durch die digitalen Touren.",
                        "Spannende Kamera-Mitfahrten durch Maschinen und Hochregallager.",
                        "Übersichtspläne schaffen Orientierung beim virtuellen Rundgang.",
                        "Mehr als 270 Minuten Videodauer, 130 Stationen und 150 Hotspots allein in Lüdenscheid"                        
                    ]}
                />
            </ListenModule>
            

            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default AbbDigitalFactoryTours